//////////////////////////////////////////////////////////////////////
//Slider1
$(document).ready(function() {
  
  var width = $('#slider-container').width();

  //dimensions
  $('.slides>li').width(width);
  $('.slides').width(width*$('.slides>li').length);
  
  //positioning
   $('.slides').css('left',-width);
   $('.slides>li:last-child').prependTo('.slides');
  
  //move slides forward
  function nextSlide() {
    $('.slides').animate({
      'margin-left':-width
    },500, function() {
      $('.slides>li:first-child').appendTo('.slides');
      $('.slides').css('margin-left', 0);
    });
  }
  //move slides backwards
  function prevSlide() {
    $('.slides').animate({
      'margin-left':width
    },500, function() {
      $('.slides>li:last-child').prependTo('.slides');
      $('.slides').css('margin-left', 0);
    });
  }
    
  //controls
  $('.next').click(nextSlide);
  $('.prev').click(prevSlide);
});

////////////////////////////////////////
//////////////////////////////////////////////////////////////////////
//Slider2
$(document).ready(function() {
  
  var width = $('#slider-container2').width();

  //dimensions
  $('.slides2>li').width(width);
  $('.slides2').width(width*$('.slides2>li').length);
  
  //positioning
   $('.slides2').css('left',-width);
   $('.slides2>li:last-child').prependTo('.slides2');
  
  //move slides forward
  function nextSlide() {
    $('.slides2').animate({
      'margin-left':-width
    },500, function() {
      $('.slides2>li:first-child').appendTo('.slides2');
      $('.slides2').css('margin-left', 0);
    });
  }
  //move slides backwards
  function prevSlide() {
    $('.slides2').animate({
      'margin-left':width
    },500, function() {
      $('.slides2>li:last-child').prependTo('.slides2');
      $('.slides2').css('margin-left', 0);
    });
  }
    
  //controls
  $('.next2').click(nextSlide);
  $('.prev2').click(prevSlide);
});


//////////////////////////////////////////////////////////////////////
//Preloader-1

$(document).ready(function () {
	
	$(function () {
		var imgs = [];

		$.each($('*'), function(){
			var $this = $(this),
			background = $this.css('background-image'),
			img = $this.is('img');
			

		if (background != 'none') {
			var path = background.replace('url("', '').replace('")', '');
			imgs.push(path);
		}

		if (img) {
			var path = $this.attr('src');
			if (path) {
				imgs.push(path);
			}
		}
		});


		var percents = 1;

		for (var i = 0; i < imgs.length; i++) {
			var image = $('<img>', {
				attr: {
					src : imgs[i]
				}
			});

			image.load(function () {
				setPercents(imgs.length, percents)
				percents++;
			});
		}

		function setPercents(total, current) {
			var percent = current/total*10;

			if (percent >= 70) {
				$('.wrap__index').css('display', 'block');
			}

			$('.loader-bar').css({
				'width' : percent + '%'
			}); //.text(percent + '%');
		}

	});
});

//////////////////////////////////////////////////////////////////////
//Preloader-2

$(window).load(function() { 
	$('#status').delay(10).fadeOut(); 
	$('#preloader').delay(10).fadeOut('slow'); 
	$('.wrap__index').css('display', 'block');
});

///////////////////////////////////////////
  ///////////////Preloader3
$(window).load(function() { 
  $(".loader_inner").fadeOut(); 
  $(".loader").delay(200).fadeOut("slow"); 
});

///////////////////////////////////////
//Animated.css

$(window).scroll(function() {
    $('.mov1').each(function(){
      var imagePos = $(this).offset().top;
      var topOfWindow = $(window).scrollTop();
      if (imagePos < topOfWindow+450) {
        $(this).addClass('zoomIn');
      }
    });
  });

$(window).scroll(function() {
    $('.mov2').each(function(){
      var imagePos = $(this).offset().top;
      var topOfWindow = $(window).scrollTop();
      if (imagePos < topOfWindow+250) {
        $(this).addClass('pulse');
      }
    });
  });

$(window).scroll(function() {
    $('.mov3').each(function(){
      var imagePos = $(this).offset().top;
      var topOfWindow = $(window).scrollTop();
      if (imagePos < topOfWindow+350) {
        $(this).addClass('rotateIn');
      }
    });
  });

$(window).scroll(function() {
    $('.mov4').each(function(){
      var imagePos = $(this).offset().top;
      var topOfWindow = $(window).scrollTop();
      if (imagePos < topOfWindow+500) {
        $(this).addClass('bounceInLeft');
      }
    });
  });

$(window).scroll(function() {
    $('.mov5').each(function(){
      var imagePos = $(this).offset().top;
      var topOfWindow = $(window).scrollTop();
      if (imagePos < topOfWindow+500) {
        $(this).addClass('bounceInRight');
      }
    });
  });

$(window).scroll(function() {
    $('.mov6').each(function(){
      var imagePos = $(this).offset().top;
      var topOfWindow = $(window).scrollTop();
      if (imagePos < topOfWindow+500) {
        $(this).addClass('swing');
      }
    });
  });

$(window).scroll(function() {
    $('.mov7').each(function(){
      var imagePos = $(this).offset().top;
      var topOfWindow = $(window).scrollTop();
      if (imagePos < topOfWindow+500) {
        $(this).addClass('bounce');
      }
    });
  });


$(window).scroll(function() {
    $('.mov8').each(function(){
      var imagePos = $(this).offset().top;
      var topOfWindow = $(window).scrollTop();
      if (imagePos < topOfWindow+600) {
        $(this).addClass('swing');
      }
    });
  });


////////////////////////////////////////////
//Forma_obratnoy_sviazi
$(document).ready(function() {
  
  $("#form").submit(function() {
    $.ajax({
      type: "POST",
      url: "mail.php",
      data: $(this).serialize()
    }).done(function() {
      alert("Thank you!");
//      setTimeout(function() {
//      
//        $("#form").trigger("reset");
//      }, 1000);
    });
    return false;
  });
});


